export default {
  badge: 'Badge',
  open: 'Ouvrir',
  close: 'Fermer',
  dismiss: 'Ignorer',
  confirmEdit: {
    ok: 'OK',
    cancel: 'Annuler',
  },
  dataIterator: {
    noResultsText: 'Aucun enregistrement correspondant trouvé',
    loadingText: `Chargement de l'élément...`,
  },
  dataTable: {
    itemsPerPageText: 'Lignes par page :',
    ariaLabel: {
      sortDescending: 'Tri décroissant.',
      sortAscending: 'Tri croissant.',
      sortNone: 'Non trié.',
      activateNone: 'Activer pour supprimer le tri.',
      activateDescending: 'Activer pour trier par ordre décroissant.',
      activateAscending: 'Activer pour trier par ordre croissant.',
    },
    sortBy: 'Trier par',
  },
  dataFooter: {
    itemsPerPageText: 'Éléments par page :',
    itemsPerPageAll: 'Tous',
    nextPage: 'Page suivante',
    prevPage: 'Page précédente',
    firstPage: 'Première page',
    lastPage: 'Dernière page',
    pageText: '{0}-{1} de {2}',
  },
  dateRangeInput: {
    divider: 'à',
  },
  datePicker: {
    itemsSelected: '{0} sélectionné(s)',
    range: {
      title: 'Sélectionner des dates',
      header: 'Entrer des dates',
    },
    title: 'Sélectionner une date',
    header: 'Entrer une date',
    input: {
      placeholder: 'Entrer une date',
    },
  },
  noDataText: 'Aucune donnée disponible',
  carousel: {
    prev: 'Visuel précédent',
    next: 'Visuel suivant',
    ariaLabel: {
      delimiter: 'Diapositive {0} de {1}',
    },
  },
  calendar: {
    moreEvents: '{0} de plus',
    today: 'Aujourd\'hui',
  },
  input: {
    clear: 'Vider {0}',
    prependAction: '{0} action avant',
    appendAction: '{0} action après',
    otp: 'Caractère {0} du mot de passe à usage unique',
  },
  fileInput: {
    counter: '{0} fichier(s)',
    counterSize: '{0} fichier(s) ({1} au total)',
  },
  fileUpload: {
    title: 'Glissez-déposez des fichiers ici',
    divider: 'ou',
    browse: 'Parcourir les fichiers',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
    title: 'Sélectionner une heure',
  },
  pagination: {
    ariaLabel: {
      root: 'Navigation de pagination',
      next: 'Page suivante',
      previous: 'Page précédente',
      page: 'Aller à la page {0}',
      currentPage: 'Page actuelle, Page {0}',
      first: 'Première page',
      last: 'Dernière page',
    },
  },
  stepper: {
    next: 'Suivant',
    prev: 'Précédent',
  },
  rating: {
    ariaLabel: {
      item: 'Note de {0} sur {1}',
    },
  },
  loading: 'Chargement...',
  infiniteScroll: {
    loadMore: 'Charger plus',
    empty: 'Aucune donnée supplémentaire',
  },
}
